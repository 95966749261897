import React, { useEffect, useMemo, useRef, useState } from "react";
import MainViewContainer from "./mainViewContainer/MainViewContainer";
import SideViewContainer from "./sideViewContainer/SideViewContainer";
import TopBar from "./TopBar";
import {
  meetingLayouts,
  appThemes,
  useMeetingAppContext,
  sideBarModes,
} from "../MeetingAppContextDef";
import useSortActiveParticipants from "./useSortActiveParticipants";
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';
import { TextField, Chip, IconButton, InputAdornment } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useMeeting } from "@videosdk.live/react-sdk";
import useIsTab from "../utils/useIsTab";
import useIsMobile from "../utils/useIsMobile";
import { usePubSub, Constants } from "@videosdk.live/react-sdk";
import {
  appEvents,
  eventEmitter,
  getUniqueId,
  json_verify,
  nameTructed,
  trimSnackBarText,
} from "../utils/common";
import { useSnackbar } from "notistack";
import useResponsiveSize from "../utils/useResponsiveSize";
import useRaisedHandParticipants from "./useRaisedHandParticipants";
import MediaRequested from "../components/MediaRequested";
import RequestedEntries from "../components/RequestedEntries";
import ClickAnywhereToContinue from "../components/ClickAnywhereToContinue";
import PinnedLayoutViewContainer from "./pinnedLayoutViewContainer/PinnedLayoutViewContainer";
import ParticipantsAudioPlayer from "./mainViewContainer/ParticipantsAudioPlayer";
import useWhiteBoard from "./useWhiteBoard";
import ConfirmBox from "../components/ConfirmBox";
import WaitingToJoin from "../components/WaitingToJoin";
import HLSContainer from "./hlsViewContainer/HLSContainer";
import ModeListner from "../components/ModeListner";
import useIsRecording from "./useIsRecording";
import useIsLivestreaming from "./useIsLivestreaming";
import useIsHls from "./useIsHls";
import PauseInvisibleParticipants from "./mainViewContainer/PauseInvisibleParticipants";
import {
  meetingModes,
  RECORDER_MAX_GRID_SIZE,
  RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED,
} from "../CONSTS";
import { Box } from "@mui/material";
import { border, useTheme } from "@mui/system";
import PollsListner from "../components/PollListner";
import RecordingLoader from "../components/RecordingLoader";
import useCustomTrack from "../utils/useCustomTrack";
import ResolutionListner from "../components/ResolutionListner";
import RealTimeCaptionProvider from "../components/RealTimeCaptionProvider";
import { Activities, Chat, Participants } from "../icons";
import OutlineIconButton from "../components/OutlineIconButton";
import MobileIconButton from "../components/MobileIconButton";
import ConfigIcon from "../icons/ConfigIcon";
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
import { Gesture, Settings } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { emailInvite } from "../../Services/ShowsApi";
import { Snackbar } from '@mui/material';
import { useLocation } from "react-router-dom";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddParticipantsBTN from "./AddParticipantsBTN";
import { addNoParticipantStartTime } from "../../Services/MeetingApi";
import { useDispatch, useSelector } from "react-redux";
import { setDataValue } from "../../redux/dataSlice"
import { addData, removeData } from "../../redux/dataSlice"
import { setBannerPosition } from "../../redux/bannerSlice"
import { right } from "@popperjs/core";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import autioFile from '../../Assests/notification-alert-269289.mp3'

const getPinMsg = ({
  localParticipant,
  participantId,
  pinnedBy,
  state,
  pinnedByDisplayName,
  partcipantDisplayName,
}) => {
  const pinEnabled = state.cam || state.share;
  const pinnedSelf = participantId === pinnedBy;
  const pinStateText = pinEnabled ? "added" : "removed";
  const localParticipantWasPinned = localParticipant === participantId;
  const localParticipantPinned = localParticipant === pinnedBy;

  if (pinnedSelf && localParticipantWasPinned && localParticipantPinned) {
    return `You ${pinStateText} yourself ${pinStateText === 'added' ? 'to' : 'from'} the main stage`;
  } else if (pinnedSelf && !localParticipantWasPinned) {
    return `${nameTructed(partcipantDisplayName, 15)} was ${pinStateText} ${pinStateText === 'added' ? 'to' : 'from'} the main stage`;
  } else if (!localParticipantWasPinned && !localParticipantPinned) {
    return `${nameTructed(
      partcipantDisplayName,
      15
    )} was ${pinStateText} by ${nameTructed(pinnedByDisplayName, 15)} ${pinStateText === 'added' ? 'to' : 'from'} the main stage`;
  } else if (localParticipantWasPinned && !localParticipantPinned) {
    return `You were ${pinStateText} by ${nameTructed(
      pinnedByDisplayName,
      15
    )} ${pinStateText === 'added' ? 'to' : 'from'} the main stage`;
  } else if (!localParticipantWasPinned && localParticipantPinned) {
    return ` You ${pinStateText} ${nameTructed(partcipantDisplayName, 15)}`;
  } else if (!pinnedBy) {
    if (localParticipantWasPinned) {
      return `You were ${pinStateText}`;
    } else {
      return `${nameTructed(partcipantDisplayName, 15)} was ${pinStateText}`;
    }
  }
};

const MeetingContainer = () => {

  const bannerPosition = useSelector((state) => state?.banner);
  const [dragging, setDragging] = useState(false);
  const [countPartcipants, setCountPartcipants] = useState(0)

  const dispatch = useDispatch();
  const participats = useSelector((state) => state?.data)
  console.log("participats", participats)

  let notificationAudio = new Audio(autioFile)

  useEffect(() => {

    if ((participats?.item_id)?.length > countPartcipants) {
      notificationAudio.play()
    }
    setCountPartcipants((participats?.item_id)?.length)
  }, [participats])

  const handleOnEntryRequested = ({ participantId, name, allow, deny }) => {
    const updatedCondition = { participantId, name, allow, deny }
    dispatch(addData(updatedCondition))
  };

  const handleOnEntryResponded = (participantId, decision) => {
    dispatch(removeData({ participantId }));
  };

  useMeeting({
    onEntryRequested: handleOnEntryRequested,
    onEntryResponded: handleOnEntryResponded,
  });

  const showJoinNotificationRef = useRef(false);
  const localParticipantAutoPinnedOnShare = useRef(false);

  const mMeetingRef = useRef();

  const [containerHeight, setContainerHeight] = useState(0);
  const [meetingError, setMeetingError] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] =
    useState(null);
  const { enqueueSnackbar } = useSnackbar();
  useWhiteBoard();

  useSortActiveParticipants();
  const { participantRaisedHand } = useRaisedHandParticipants();
  const isLiveStreaming = useIsLivestreaming();
  const isRecording = useIsRecording();
  const isHls = useIsHls();

  const isLiveStreamingRef = useRef(isLiveStreaming);
  const isRecordingRef = useRef(isRecording);
  const isHlsRef = useRef(isHls);

  const sideBarContainerWidth = useResponsiveSize({
    xl: 400,
    lg: 360,
    md: 320,
    sm: 280,
    xs: 240,
  });

  useEffect(() => {
    containerRef.current?.offsetHeight &&
      setContainerHeight(containerRef.current.offsetHeight);
    containerRef.current?.offsetWidth &&
      setContainerWidth(containerRef.current.offsetWidth);

    window.addEventListener("resize", ({ target }) => {
      containerRef.current?.offsetHeight &&
        setContainerHeight(containerRef.current.offsetHeight);
      containerRef.current?.offsetWidth &&
        setContainerWidth(containerRef.current.offsetWidth);
    });
  }, []);

  useEffect(() => {
    isLiveStreamingRef.current = isLiveStreaming;
  }, [isLiveStreaming]);

  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);

  useEffect(() => {
    isHlsRef.current = isHls;
  }, [isHls]);

  const {
    redirectOnLeave,
    sideBarMode,
    containerRef,
    participantCanToggleRecording,
    participantCanToggleHls,
    participantCanToggleLivestream,
    autoStartLiveStream,
    autoStartRecording,
    autoStartHls,
    recordingWebhookUrl,
    recordingAWSDirPath,
    liveStreamOutputs,
    askJoin,
    notificationSoundEnabled,
    meetingLayout,
    selectedMic,
    selectedWebcam,
    joinScreenWebCam,
    joinScreenMic,
    joinWithoutUserInteraction,
    webcamEnabled,
    canDrawOnWhiteboard,
    setMeetingLeft,
    appMeetingLayout,
    setAppMeetingLayout,
    topbarEnabled,
    notificationAlertsEnabled,
    participantNotificationAlertsEnabled,
    debug,
    meetingLayoutTopic,
    setLiveStreamConfig,
    liveStreamConfig,
    meetingMode,
    isRecorder,
    setDownstreamUrl,
    setAfterMeetingJoinedHLSState,
    appTheme,
    recordingTheme,
    hlsTheme,
    liveStreamTheme,
    cameraId,
    realtimeTranscriptionVisible,
  } = useMeetingAppContext();
  localStorage.setItem('originalContainerWidth', containerRef.current?.offsetWidth);

  const joinWithoutUserInteractionValue =
    joinWithoutUserInteraction === "true" ? true : false;

  const webcamEnabledValue = webcamEnabled === "true" ? true : false;

  const { getCustomAudioTrack, getCustomVideoTrack } = useCustomTrack();

  const topBarHeight = topbarEnabled ? 60 : 0;

  const isTab = useIsTab();
  const isMobile = useIsMobile();

  const { type, priority, gridSize } = useMemo(
    () => ({
      type: appMeetingLayout.type,
      priority: appMeetingLayout.priority,
      gridSize: appMeetingLayout.gridSize,
    }),
    [appMeetingLayout]
  );

  const liveStreamConfigRef = useRef(liveStreamConfig);
  const typeRef = useRef(type);
  const priorityRef = useRef(priority);
  const gridSizeRef = useRef(gridSize);
  const meetingModeRef = useRef(meetingMode);

  useEffect(() => {
    liveStreamConfigRef.current = liveStreamConfig;
  }, [liveStreamConfig]);

  useEffect(() => {
    typeRef.current = type;
  }, [type]);

  useEffect(() => {
    priorityRef.current = priority;
  }, [priority]);

  useEffect(() => {
    gridSizeRef.current = gridSize;
  }, [gridSize]);

  useEffect(() => {
    meetingModeRef.current = meetingMode;
  }, [meetingMode]);

  usePubSub(meetingLayoutTopic, {
    onMessageReceived: (data) => {
      setAppMeetingLayout({
        ...data.message.layout,
        gridSize: isRecorder
          ? mMeetingRef.current?.presenterId
            ? data.message.layout.gridSize >
              RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
              ? RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
              : data.message.layout.gridSize
            : data.message.layout.gridSize > RECORDER_MAX_GRID_SIZE
              ? RECORDER_MAX_GRID_SIZE
              : data.message.layout.gridSize
          : data.message.layout.gridSize,
      });
    },
    onOldMessagesReceived: (messages) => {
      const latestMessage = messages.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      })[0];

      if (latestMessage) {
        setAppMeetingLayout({
          ...latestMessage.message.layout,
          gridSize: isRecorder
            ? mMeetingRef.current?.presenterId
              ? latestMessage.message.layout.gridSize >
                RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
                ? RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
                : latestMessage.message.layout.gridSize
              : latestMessage.message.layout.gridSize > RECORDER_MAX_GRID_SIZE
                ? RECORDER_MAX_GRID_SIZE
                : latestMessage.message.layout.gridSize
            : latestMessage.message.layout.gridSize,
        });
      }
    },
  });

  const { publish: liveStreamConfigPublish } = usePubSub("LIVE_STREAM_CONFIG", {
    onMessageReceived: (data) => {
      setLiveStreamConfig(data.message.config);
    },

    onOldMessagesReceived: (messages) => {
      const latestMessage = messages.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      })[0];

      if (latestMessage) {
        setLiveStreamConfig(latestMessage.message.config);
      }
    },
  });

  const liveStreamConfigPublishRef = useRef();

  useEffect(() => {
    liveStreamConfigPublishRef.current = liveStreamConfigPublish;
  }, [liveStreamConfigPublish]);

  const _handleOnMeetingJoined = async () => {
    const { changeWebcam, changeMic, muteMic, disableWebcam } =
      mMeetingRef.current;
    console.log('guest', guest);
    if (guest !== 'true') {
      mMeetingRef.current?.localParticipant.pin()
    }

    setTimeout(() => {
      const { startLivestream, startRecording, startHls } = mMeetingRef.current;

      const isLiveStreaming = isLiveStreamingRef.current;
      const isRecording = isRecordingRef.current;
      const isHls = isHlsRef.current;

      const outputs = liveStreamConfigRef?.current?.length
        ? liveStreamConfigRef.current
        : liveStreamOutputs?.length
          ? liveStreamOutputs
          : null;

      const type = typeRef.current;
      const priority = priorityRef.current;
      const gridSize = gridSizeRef.current;

      const layout = { type, priority, gridSize };

      //
      //

      if (autoStartLiveStream && !isLiveStreaming && outputs?.length) {
        startLivestream(outputs, { layout, theme: liveStreamTheme });

        liveStreamConfigPublishRef.current(
          {
            config: outputs.map((output) => {
              return { ...output, id: getUniqueId() };
            }),
          },
          { persist: true }
        );
      }

      //
      //

      if (autoStartRecording && !isRecording) {
        startRecording(recordingWebhookUrl, recordingAWSDirPath, {
          layout,
          theme: recordingTheme,
        });
      }

      //
      //

      if (autoStartHls && !isHls) {
        startHls({ layout, theme: hlsTheme });
      }
    }, 3000);

    if (
      joinWithoutUserInteractionValue
        ? webcamEnabledValue
        : joinScreenWebCam && (cameraId || selectedWebcam.id)
    ) {
      await new Promise((resolve) => {
        disableWebcam();
        setTimeout(async () => {
          const track = await getCustomVideoTrack(
            cameraId ? cameraId : selectedWebcam.id
          );
          changeWebcam(track);
          resolve();
        }, 500);
      });
    }

    if (joinScreenMic && selectedMic.id) {
      await new Promise((resolve) => {
        muteMic();
        setTimeout(async () => {
          const audioTrack = await getCustomAudioTrack(selectedMic.id);
          changeMic(audioTrack);
          // changeMic(selectedMic.id);
          resolve();
        }, 500);
      });
    }
  };

  const loc = useLocation()
  const params = new URLSearchParams(loc.search);
  const meetingId = params.get("meetingId");

  const _handleMeetingLeft = async () => {
    if (mMeeting.pinnedParticipants.size === 0) {
      const data = {
        MeetingID: meetingId,
        StartTime: getCurrentTimestamp()
      }
      await addNoParticipantStartTime(data);
    }
    if (redirectOnLeave && redirectOnLeave !== "undefined") {
      window.location = redirectOnLeave;
    } else {
      setMeetingLeft(true);
    }
  };

  function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  usePubSub("CHAT", {
    onMessageReceived: (data) => {
      const localParticipantId = mMeetingRef.current?.localParticipant?.id;

      const { senderId, senderName, message } = data;

      const isLocal = senderId === localParticipantId;

      if (!isLocal) {
        // if (notificationSoundEnabled) {
        //   new Audio(
        //     `https://static.videosdk.live/prebuilt/notification.mp3`
        //   ).play();
        // }
        if (notificationAlertsEnabled) {
          enqueueSnackbar(
            trimSnackBarText(`${nameTructed(senderName, 15)} says: ${message}`)
          );
        }
      }
    },
  });

  usePubSub("RAISE_HAND", {
    onMessageReceived: (data) => {
      const localParticipantId = mMeetingRef.current?.localParticipant?.id;

      const { senderId, senderName } = data;

      const isLocal = senderId === localParticipantId;
      // if (notificationSoundEnabled) {
      //   new Audio(
      //     `https://static.videosdk.live/prebuilt/notification.mp3`
      //   ).play();
      // }
      // if (notificationAlertsEnabled) {
      //   enqueueSnackbar(
      //     `${isLocal ? "You" : nameTructed(senderName, 15)} raised hand 🖐🏼`
      //   );
      // }
      participantRaisedHand(senderId);
    },
  });

  const _handleChatMessage = (data) => {
    const localParticipantId = mMeetingRef.current?.localParticipant?.id;

    const { senderId, senderName, text } = data;

    const isLocal = senderId === localParticipantId;

    if (json_verify(text)) {
      const { type } = JSON.parse(text);

      // if (type === "END_CALL") {
      //   // if (notificationSoundEnabled) {
      //   //   new Audio(
      //   //     `https://static.videosdk.live/prebuilt/notification.mp3`
      //   //   ).play();
      //   // }

      //   // if (notificationAlertsEnabled) {
      //   //   enqueueSnackbar(
      //   //     `${isLocal
      //   //       ? "You end the call"
      //   //       : " This meeting has been ended by host"
      //   //     }`
      //   //   );
      //   // }
      // }
    }
  };

  const _handleParticipantJoined = (data) => {
    if (showJoinNotificationRef.current) {
      const { displayName } = data;
      console.log(data);
      if (participantNotificationAlertsEnabled) {
        // if (notificationSoundEnabled) {
        //   new Audio(
        //     `https://static.videosdk.live/prebuilt/notification.mp3`
        //   ).play();
        // }
        // enqueueSnackbar(`${displayName} joined the meeting`, {});

      }
    }
  };

  const _handleParticipantLeft = (data) => {
    const { displayName } = data;
    // if (participantNotificationAlertsEnabled) {
    //   // if (notificationSoundEnabled) {
    //   //   new Audio(
    //   //     `https://static.videosdk.live/prebuilt/notification.mp3`
    //   //   ).play();
    //   // }
    //   enqueueSnackbar(`${displayName} left the meeting`, {});
    // }
  };

  const _handlePresenterChanged = (presenterId) => {
    // reduce grid size in recorder if presenter changes
    if (isRecorder) {
      if (presenterId) {
        setAppMeetingLayout((s) => ({
          ...s,
          gridSize:
            s.gridSize > RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
              ? RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED
              : s.gridSize,
        }));
      } else {
        setAppMeetingLayout((s) => ({
          ...s,
          gridSize:
            s.gridSize > RECORDER_MAX_GRID_SIZE
              ? RECORDER_MAX_GRID_SIZE
              : s.gridSize,
        }));
      }
    }

    if (!presenterId && localParticipantAutoPinnedOnShare.current === true) {
      mMeetingRef.current?.localParticipant.unpin();
      localParticipantAutoPinnedOnShare.current = false;
    }

    const localParticipantId = mMeetingRef.current?.localParticipant?.id;
    const participants = mMeetingRef.current?.participants;
    const pinnedParticipants = new Map(mMeetingRef.current?.pinnedParticipants);

    const mPresenter = participants.get(presenterId);

    const isLocal = presenterId === localParticipantId;

    if (mPresenter) {
      if (isLocal) {
        if (pinnedParticipants.size) {
          const localIsPinned = pinnedParticipants.get(presenterId);
          if (!localIsPinned) {
            localParticipantAutoPinnedOnShare.current = true;

            mMeetingRef.current?.localParticipant.pin();
          }
        }
      }
      // if (
      //   notificationSoundEnabled &&
      //   meetingModeRef.current === meetingModes.CONFERENCE
      // ) {
      //   new Audio(
      //     `https://static.videosdk.live/prebuilt/notification.mp3`
      //   ).play();
      // }

      // if (
      //   notificationAlertsEnabled &&
      //   meetingModeRef.current === meetingModes.CONFERENCE
      // ) {
      //   enqueueSnackbar(
      //     `${isLocal ? "You" : nameTructed(mPresenter.displayName, 15)
      //     } started presenting`
      //   );
      // }
    }
  };

  const _handleOnRecordingStarted = () => { };

  const _handleOnRecordingStopped = () => { };

  const _handleOnLiveStreamStarted = () => { };

  const _handleOnLiveStreamStopped = () => { };

  const _handleOnRecordingStateChanged = ({ status }) => {
    if (
      participantCanToggleRecording &&
      notificationAlertsEnabled &&
      meetingModeRef.current === meetingModes.CONFERENCE &&
      (status === Constants.recordingEvents.RECORDING_STARTED ||
        status === Constants.recordingEvents.RECORDING_STOPPED)
    ) {
      console.log("meeting is either started or stopped")
      // enqueueSnackbar(
      //   status === Constants.recordingEvents.RECORDING_STARTED
      //     ? "Meeting recording is started."
      //     : "Meeting recording is stopped."
      // );
    }
  };

  const _handleOnLivestreamStateChanged = ({ status }) => {
    if (
      participantCanToggleLivestream &&
      notificationAlertsEnabled &&
      meetingModeRef.current === meetingModes.CONFERENCE &&
      (status === Constants.livestreamEvents.LIVESTREAM_STARTED ||
        status === Constants.livestreamEvents.LIVESTREAM_STOPPED)
    ) {
      enqueueSnackbar(
        status === Constants.livestreamEvents.LIVESTREAM_STARTED
          ? "Meeting livestreaming is started."
          : "Meeting livestreaming is stopped."
      );
    }
  };

  const _handleOnMeetingStateChanged = (data) => {
    const { state } = data;

    // enqueueSnackbar(
    //   state === "CONNECTED"
    //     ? "Meeting is connected"
    //     : state === "CONNECTING"
    //       ? "Meeting is connecting"
    //       : state === "FAILED"
    //         ? "Meeting connection failed"
    //         : state === "DISCONNECTED"
    //           ? "Meeting connection disconnected abruptly"
    //           : state === "CLOSING"
    //             ? "Meeting is closing"
    //             : state === "CLOSED"
    //               ? "Meeting connection closed"
    //               : ""
    // );
  };

  const _handleOnHlsStateChanged = (data) => {
    //
    if (
      participantCanToggleHls &&
      notificationAlertsEnabled &&
      meetingModeRef.current === meetingModes.CONFERENCE && // trigger on conference mode only
      (data.status === Constants.hlsEvents.HLS_STARTED ||
        data.status === Constants.hlsEvents.HLS_STOPPED)
    ) {
      enqueueSnackbar(
        data.status === Constants.hlsEvents.HLS_STARTED
          ? "Meeting HLS is started."
          : "Meeting HLS is stopped."
      );
    }

    // if (
    //   data.status === Constants.hlsEvents.HLS_STARTED ||
    //   data.status === Constants.hlsEvents.HLS_STOPPED
    // ) {
    //   setDownstreamUrl(
    //     data.status === Constants.hlsEvents.HLS_STARTED
    //       ? data.downstreamUrl
    //       : null
    //   );
    // }

    if (data.status === Constants.hlsEvents.HLS_PLAYABLE) {
      setAfterMeetingJoinedHLSState("STARTED");
    }

    if (data.status === Constants.hlsEvents.HLS_STOPPED) {
      setAfterMeetingJoinedHLSState("STOPPED");
    }

    //set downstream url on basis of started or stopped
  };

  const _handleOnHlsStarted = (data) => { };

  const _handleOnHlsStopped = () => { };

  const _handleOnEntryRequested = () => { };

  const _handleOnEntryResponded = (participantId, decision) => {
    if (mMeetingRef.current?.localParticipant?.id === participantId) {
      if (decision === "allowed") {
        setLocalParticipantAllowedJoin(true);
      } else {
        setLocalParticipantAllowedJoin(false);
        setTimeout(() => {
          _handleMeetingLeft();
        }, 3000);
      }
    }
  };

  const _handleOnPinStateChanged = (data) => {
    let localParticipant = mMeetingRef.current?.localParticipant?.id;
    let participantId = data.participantId;
    let pinnedBy = data.pinnedBy;
    let state = data.state;

    const pinnedByDisplayName =
      mMeetingRef.current.participants.get(pinnedBy)?.displayName || "";
    const partcipantDisplayName =
      mMeetingRef.current.participants.get(participantId)?.displayName || "";

    if (
      showJoinNotificationRef.current &&
      notificationAlertsEnabled &&
      meetingModeRef.current === meetingModes.CONFERENCE
    ) {
      // enqueueSnackbar(
      //   getPinMsg({
      //     localParticipant,
      //     participantId,
      //     pinnedBy,
      //     state,
      //     partcipantDisplayName,
      //     pinnedByDisplayName,
      //   })
      // );
    }
  };

  const _handleOnError = (data) => {
    const { code, message } = data;

    const joiningErrCodes = [
      4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010,
    ];

    const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
    const isCriticalError = `${code}`.startsWith("500");

    // new Audio(
    //   isCriticalError
    //     ? `https://static.videosdk.live/prebuilt/notification_critical_err.mp3`
    //     : `https://static.videosdk.live/prebuilt/notification_err.mp3`
    // ).play();

    setMeetingError({
      code,
      message: debug
        ? message
        : isJoiningError
          ? "Unable to join meeting!"
          : message,
    });
  };

  const ParticipantsBTN = ({ onClick, isMobile, isTab }) => {
    const {
      sideBarMode,
      setSideBarMode,
      meetingMode,
      canToggleParticipantTab,
      appTheme,
    } = useMeetingAppContext();

    const mMeeting = useMeeting();
    const theme = useTheme();
    const participants = mMeeting?.participants;
    const participantsCount = participants ? new Map(participants).size : 0;

    return isMobile || isTab ? (
      <MobileIconButton
        tooltipTitle={"Participants"}
        isFocused={sideBarMode === sideBarModes.PARTICIPANTS}
        buttonText={"Participants"}
        disabledOpacity={1}
        Icon={Participants}
        disabled={meetingMode === meetingModes.VIEWER || !canToggleParticipantTab}
        onClick={() => {
          typeof onClick === "function" && onClick();
          setSideBarMode((s) =>
            s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS
          );
        }}
        badge={participantsCount}
      />
    ) : (
      <div
        onClick={() => {
          typeof onClick === "function" && onClick();
          setSideBarMode((s) =>
            s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.PARTICIPANTS ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.PARTICIPANTS ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.PARTICIPANTS ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
          position: 'relative'
        }}
        title="Participants"
      >
        {(participats?.item_id)?.length > 0 ? (<span style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          backgroundColor: 'red',
          top: '-10px', right: "30%",
          position: 'absolute',
          fontSize: '10px',
          borderRadius: "50%",
          padding: '0px 4px'
        }}>{(participats?.item_id)?.length}</span>) : null}

        <PeopleRoundedIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.PARTICIPANTS ? 'black' : 'gray' }} />
        <span className="ellipsis text-center" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.PARTICIPANTS ? 'black' : 'gray' }}>
          Participants
          {guest !== 'true' &&
            <>
              <br />
              <span> & Layouts</span>
            </>
          }
        </span>
      </div>

    );
  };
  const ConfigBTN = ({ isMobile, isTab }) => {
    const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
      useMeetingAppContext();
    const theme = useTheme();

    return isMobile || isTab ? (
      <MobileIconButton
        tooltipTitle={"Configuration"}
        buttonText={"Configuration"}
        Icon={ConfigIcon}
        isFocused={sideBarMode === sideBarModes.CONFIGURATION}
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.CONFIGURATION ? null : sideBarModes.CONFIGURATION
          );
        }}
      />
    ) : (
      <div
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.CONFIGURATION ? null : sideBarModes.CONFIGURATION
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.CONFIGURATION ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.CONFIGURATION ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.CONFIGURATION ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="Layouts"
      >
        <ViewModuleRoundedIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.CONFIGURATION ? 'black' : 'gray' }} />
        <span className="ellipsis" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.CONFIGURATION ? 'black' : 'gray' }}>
          Layouts
        </span>
      </div>
    );
  };

  const AIAssistantBTN = () => {
    const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
      useMeetingAppContext();
    const theme = useTheme();

    return (
      <div
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.AI_ASSISTANT ? null : sideBarModes.AI_ASSISTANT
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.AI_ASSISTANT ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.AI_ASSISTANT ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.AI_ASSISTANT ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="AI Assistant"
      >
        <SmartToyIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.AI_ASSISTANT ? 'black' : 'gray' }} />
        <span className="ellipsis" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.AI_ASSISTANT ? 'black' : 'gray' }}>
          AI Assistant
        </span>
      </div>
    )
  }

  const MeetingBackgroundBTN = () => {
    const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
      useMeetingAppContext();
    const theme = useTheme();

    return (
      <div
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.MEETING_BACKGROUND ? null : sideBarModes.MEETING_BACKGROUND
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.MEETING_BACKGROUND ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.MEETING_BACKGROUND ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.MEETING_BACKGROUND ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="Meeting Background"
      >
        <PaletteRoundedIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.MEETING_BACKGROUND ? 'black' : 'gray' }} />
        <span className="ellipsis" style={{ textAlign: 'center', fontSize: '12px', color: sideBarMode === sideBarModes.MEETING_BACKGROUND ? 'black' : 'gray' }}>
        Canvas
        <>
          <br />
              <span>Settings</span>
            </>
        </span>
      </div>
    )
  }
  const ChatBTN = ({ isMobile, isTab }) => {
    const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
      useMeetingAppContext();
    const theme = useTheme();

    return isMobile || isTab ? (
      <MobileIconButton
        tooltipTitle={"Chat"}
        buttonText={"Chat"}
        Icon={Chat}
        isFocused={sideBarMode === sideBarModes.CHAT}
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.CHAT ? null : sideBarModes.CHAT
          );
        }}
      />
    ) : (
      <div
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.CHAT ? null : sideBarModes.CHAT
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.CHAT ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.CHAT ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.CHAT ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="Chat"
      >
        <ChatRoundedIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.CHAT ? 'black' : 'gray' }} />
        <span className="ellipsis" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.CHAT ? 'black' : 'gray' }}>
          Chat
        </span>
      </div>

    );
  };
  const ActivitiesBTN = ({ onClick, isMobile, isTab }) => {
    const { sideBarMode, setSideBarMode, setSideBarNestedMode, appTheme } =
      useMeetingAppContext();
    const theme = useTheme();

    return isMobile || isTab ? (
      <MobileIconButton
        Icon={Activities}
        tooltipTitle={"More Options"}
        buttonText={"More Options"}
        isFocused={sideBarMode === sideBarModes.ACTIVITIES}
        onClick={() => {
          typeof onClick === "function" && onClick();

          setSideBarMode((s) =>
            s === sideBarModes.ACTIVITIES ? null : sideBarModes.ACTIVITIES
          );

          setSideBarNestedMode(null);
        }}
      />
    ) : (
      <div
        onClick={() => {
          typeof onClick === "function" && onClick();
          setSideBarMode((s) =>
            s === sideBarModes.ACTIVITIES ? null : sideBarModes.ACTIVITIES
          );
          setSideBarNestedMode(null);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.ACTIVITIES ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.ACTIVITIES ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.ACTIVITIES ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="More Options"
      >
        <FontAwesomeIcon icon={faCubes} style={{ fontSize: '24px', color: sideBarMode === sideBarModes.ACTIVITIES ? 'black' : 'gray' }} />
        {/* <Activities style={{ fontSize: '24px', color: sideBarMode === sideBarModes.ACTIVITIES ? 'black' : 'gray' }} /> */}
        <span className="ellipsis" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.ACTIVITIES ? 'black' : 'gray' }}>
          Options
        </span>
      </div>
    );
  };
  const WhiteBoardBTN = ({ onClick, isMobile, isTab }) => {
    const {
      whiteboardStarted,
      whiteboardEnabled,
      canToggleWhiteboard,
      appTheme,
    } = useMeetingAppContext();
    const theme = useTheme();

    const mMeeting = useMeeting({});

    const presenterId = mMeeting?.presenterId;

    return (
      <>
        {whiteboardEnabled &&
          (isMobile || isTab ? (
            <MobileIconButton
              disabled={presenterId || !canToggleWhiteboard}
              tooltipTitle={"Whiteboard"}
              buttonText={"Whiteboard"}
              Icon={Gesture}
              isFocused={whiteboardStarted}
              focusIconColor={
                appTheme === appThemes.LIGHT &&
                "#95959E"
              }
              onClick={() => {
                typeof onClick === "function" && onClick();

                whiteboardStarted
                  ? mMeeting.meeting.stopWhiteboard()
                  : mMeeting.meeting.startWhiteboard();
              }}
            />
          ) : (
            <OutlineIconButton
              disabled={presenterId || !canToggleWhiteboard}
              tooltipTitle={"Whiteboard"}
              Icon={Gesture}
              isFocused={whiteboardStarted}
              focusBGColor={
                appTheme === appThemes.LIGHT &&
                "#95959E"
              }
              onClick={() => {
                typeof onClick === "function" && onClick();

                whiteboardStarted
                  ? mMeeting.meeting.stopWhiteboard()
                  : mMeeting.meeting.startWhiteboard();
              }}
            />
          ))}
      </>
    );
  };

  const BannersBTN = () => {
    const { sideBarMode, setSideBarMode, appTheme, setSideBarNestedMode } =
      useMeetingAppContext();
    const theme = useTheme();

    return (
      <div
        onClick={() => {
          setSideBarNestedMode(null);
          setSideBarMode((s) =>
            s === sideBarModes.BANNERS ? null : sideBarModes.BANNERS
          );
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '15%',
          paddingLeft: '10px',
          minWidth: '100%',
          paddingRight: '10px',
          backgroundColor: sideBarMode === sideBarModes.BANNERS ? 'white' : 'transparent',
          color: sideBarMode === sideBarModes.BANNERS ? 'black' : 'gray',
          border: '1px solid',
          borderColor: sideBarMode === sideBarModes.BANNERS ? 'black' : 'transparent',
          borderRadius: '4px',
          transition: 'all 0.3s ease', // Smooth transition effect
        }}
        title="Banners"
      >
        <BeenhereIcon style={{ fontSize: '24px', color: sideBarMode === sideBarModes.BANNERS ? 'black' : 'gray' }} />
        <span className="ellipsis" style={{ fontSize: '12px', color: sideBarMode === sideBarModes.BANNERS ? 'black' : 'gray' }}>
          Banners
          <>
            <br />
            <span> & Logos</span>
          </>
        </span>
      </div>
    )
  }


  const mMeeting = useMeeting({
    onMeetingJoined: _handleOnMeetingJoined,
    onMeetingLeft: _handleMeetingLeft,
    onChatMessage: _handleChatMessage,
    onParticipantJoined: _handleParticipantJoined,
    onParticipantLeft: _handleParticipantLeft,
    onPresenterChanged: _handlePresenterChanged,
    onRecordingStarted: _handleOnRecordingStarted,
    onRecordingStopped: _handleOnRecordingStopped,
    onLiveStreamStarted: _handleOnLiveStreamStarted,
    onLiveStreamStopped: _handleOnLiveStreamStopped,
    onEntryRequested: _handleOnEntryRequested,
    onEntryResponded: _handleOnEntryResponded,
    onPinStateChanged: _handleOnPinStateChanged,
    onError: _handleOnError,
    onMeetingStateChanged: _handleOnMeetingStateChanged,
    onRecordingStateChanged: _handleOnRecordingStateChanged,
    onLivestreamStateChanged: _handleOnLivestreamStateChanged,
    onHlsStateChanged: _handleOnHlsStateChanged,
    onHlsStarted: _handleOnHlsStarted,
    onHlsStopped: _handleOnHlsStopped,
  });

  const _handleToggleFullScreen = () => {
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        const elem = containerRef.current;
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen().catch((e) => {
            console.log(`request to full screen is rejected due to ${e}`);
          });
        } else if (document.documentElement.webkitRequestFullscreen) {
          /*  Safari */
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          /*  IE11 */
          document.documentElement.msRequestFullscreen();
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  useEffect(() => {
    eventEmitter.on(appEvents["toggle-full-screen"], _handleToggleFullScreen);
    setTimeout(() => {
      showJoinNotificationRef.current = true;
    }, 3000);

    return () => {
      eventEmitter.off(
        appEvents["toggle-full-screen"],
        _handleToggleFullScreen
      );
    };
  }, []);

  const whiteboardToolbarWidth = canDrawOnWhiteboard ? 48 : 0;
  const whiteboardSpacing = canDrawOnWhiteboard ? 16 : 0;

  const theme = useTheme();

  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const queryParams = new URLSearchParams(location.search);
  const guest = queryParams.get("guest");
  const recorder = queryParams.get("isRecorder");
  const query = useQuery();
  const [bannerName, setBannerName] = useState('Default Banner');
  const [backgroundColor, setBackgroundColor] = useState('#00002910');
  const [fontSize, setFontSize] = useState('20px');
  const [logo, setLogo] = useState('');
  const position = queryParams.get('position') || 'bl';
  // const backgroundColor = queryParams.get('backgroundColor') || 'gray';
  const color = queryParams.get('color') || 'white';

  // useEffect(() => {
  //   setBannerName('Default Banner');
  // }, []); 

  usePubSub("UPDATE_BANNER", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log("Message", message)
      setBannerName(message?.message);  // Update bannerName state here
      setBackgroundColor(message?.message ? message?.backgroundColor : 'transparent');
      setFontSize(message?.fontSize);
      // enqueueSnackbar(`New Banner - ${message}`);
    },
    onOldMessagesReceived: (messages) => {
      console.log("Old Message published with persist:true Received", messages);
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message; // Get the most recent banner message
        setBannerName(latestMessage?.message);  // Update bannerName state
        setBackgroundColor(latestMessage?.message ? latestMessage?.backgroundColor : 'transparent'); // Update background color
        setFontSize(latestMessage?.fontSize); // Update font size
      }
    },
  });

  // usePubSub("UPDATE_LOGO", {
  //   onMessageReceived: (data) => {
  //     console.log("check here visakh")
  //     console.log(data);
  //     const { message } = data;
  //     setLogo(message?.LogoUrl);
  //   },
  //   onOldMessagesReceived: (messages) => {
  //     console.log("Old Message published with persist:true Received", messages);
  //     // Check if any old message exists, and if so, set it to the state
  //     if (messages && messages.length > 0) {
  //       const latestMessage = messages[messages.length - 1].LogoUrl; // Get the most recent banner message
  //       setLogo(latestMessage?.LogoUrl);
  //     }
  //   },
  // });


  const bannerStyles = {
    position: 'absolute',
    // left: `${bannerPosition.x}%`,
    // top: `${bannerPosition.y}%`,
    transform: position === 'cc' ? 'translate(-50%, -50%)' : 'none',
    backgroundColor: backgroundColor,
    color: color,
    padding: '10px 10px',
    fontSize: fontSize,
    textAlign: 'center',
    borderRadius: '30px',
    zIndex: 1000,
    height: '45px',
    bottom: '0px',
    left: '25px',
    right: '0',
    overflow: 'hidden',
    wordWrap: 'break-word',
  };


  // const logoStyles = {
  //   position: 'absolute',
  //   top: '0',  // Aligns it at the top
  //   left: '0', // Aligns it to the left
  //   transform: 'none',  // No transform needed for top-left
  //   backgroundColor: 'transparent',  // Background color for logo can be transparent
  //   zIndex: 1000,  // Ensures the logo stays on top
  // };

  // // Calculate aspect ratio from the initial dimensions
  const aspectRatio = ((containerWidth - (isTab || isMobile
    ? 0
    : typeof sideBarMode === "string"
      ? sideBarContainerWidth
      : 0)) / parseFloat(localStorage.getItem('originalContainerWidth')));

  const proSidebarWidth = localStorage.getItem('ProSidebarWidth')

  console.log("proSidebarWidth", proSidebarWidth);
  

  return (
    <div
      ref={containerRef}
      style={{
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        backgroundColor:
          appTheme === appThemes.LIGHT
            ? theme.palette.lightTheme.main
            : appTheme === appThemes.DARK
              ? theme.palette.darkTheme.main
              : theme.palette.background.default,
      }}
    >
      {/* <ConfirmBox
        open={meetingError}
        successText="OKAY"
        onSuccess={() => {
          setMeetingError(false);
        }}
        title={`Error Code: ${meetingError.code}`}
        subTitle={meetingError.message}
      /> */}

      {typeof localParticipantAllowedJoin === "boolean" ? (
        localParticipantAllowedJoin ? (
          <>
            <ModeListner />
            <PollsListner />
            <PauseInvisibleParticipants />
            {/* <ResolutionListner /> */}
            {realtimeTranscriptionVisible ? <RealTimeCaptionProvider /> : null}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: isTab || isMobile ? "column-reverse" : "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: containerHeight - topBarHeight,
                  position: "relative",
                }}

              >
                {meetingMode === meetingModes.CONFERENCE ? (
                  <>
                    {(meetingLayout === meetingLayouts.SPOTLIGHT ||
                      meetingLayout === meetingLayouts.SIDEBAR) ? (
                      <PinnedLayoutViewContainer
                        {...{
                          height: (containerHeight - topBarHeight) * aspectRatio,
                          width:
                            containerWidth -
                            (isTab || isMobile
                              ? 0
                              : typeof sideBarMode === "string"
                                ? sideBarContainerWidth
                                : 0) - proSidebarWidth,
                          whiteboardToolbarWidth,
                          whiteboardSpacing,
                          left: guest === 'true' ? '0px' : `${proSidebarWidth}px`
                        }}
                      />
                    ) : (
                      <>
                        <div style={{
                          height: containerHeight - topBarHeight,
                          width: containerWidth - sideBarContainerWidth
                        }}
                        >
                        </div>
                      </>

                      // <MainViewContainer
                      //   {...{
                      //     height: containerHeight - topBarHeight,
                      //     width:
                      //       containerWidth -
                      //       (isTab || isMobile
                      //         ? 0
                      //         : typeof sideBarMode === "string"
                      //         ? sideBarContainerWidth
                      //         : 0),
                      //     whiteboardToolbarWidth,
                      //     whiteboardSpacing,
                      //   }}
                      // />

                    )}
                    {/* {(bannerName !== 'Default Banner' || !bannerName) && (
                      <div style={bannerStyles}
                        // onMouseDown={handleMouseDown}
                      // onClick={handleMouseDown}
                      >
                        {bannerName}
                      </div>
                    )} */}
                    {/* {logo !== null && (
                      <div style={logoStyles}>
                        <img
                          src={logo}
                          alt="Logo"
                          style={{ width: '150px', height: '150px', objectFit: 'contain' }}  // Adjust size as needed
                        />
                      </div>
                    )} */}

                    <ParticipantsAudioPlayer />
                    <MediaRequested />
                    {/* <RequestedEntries /> */}
                  </>
                ) : (
                  <>
                    <HLSContainer
                      {...{
                        width:
                          containerWidth -
                          (isTab || isMobile
                            ? 0
                            : typeof sideBarMode === "string"
                              ? sideBarContainerWidth
                              : 0),
                      }}
                    />
                  </>
                )}
                {recorder !== 'true' && (
                  <>
                    <div
                      style={{
                        width: '6%', // Set the width of the sidebar as required
                        backgroundColor: '#19193e',
                        position: "fixed",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 2,
                        height: '100vh',
                        placeContent: 'center'
                      }}
                    >
                      <AddParticipantsBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                      <ChatBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                      <ParticipantsBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                      <ActivitiesBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                      {/* {guest !== 'true' &&
                    <ConfigBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                  } */}
                      <AIAssistantBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                      {guest !== 'true' &&
                        <>
                          <BannersBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                          <MeetingBackgroundBTN isMobile={isMobile} isTab={isTab} className="mb-2" />
                        </>
                      }

                    </div>

                    <SideViewContainer
                      {...{
                        topBarHeight,
                        width: sideBarContainerWidth,
                        height: containerHeight - topBarHeight,
                      }}
                    />
                  </>
                )}
              </div>
              {topbarEnabled && <TopBar {...{ topBarHeight }} />}
            </div>
          </>
        ) : (
          <ClickAnywhereToContinue title="Entry denied!" />
        )
      ) : askJoin ? (
        <ClickAnywhereToContinue title="Waiting to join..." />
      ) : !mMeeting.isMeetingJoined ? (
        isRecorder ? (
          <Box
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                appTheme === appThemes.DARK
                  ? theme.palette.darkTheme.slightLighter
                  : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.two
                    : theme.palette.background.default,
            }}
          >
            <RecordingLoader {...{ meetingLayout, appTheme }} />
          </Box>
        ) : (
          <WaitingToJoin />
        )
      ) : null}
    </div>
  );
};

export default MeetingContainer;