import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { getScheduledPodcasts } from '../../../Services/SchedulesApi'
import HomepodcastPreview from './HomepodcastPreview';
import Dropdowncomponent from '../../../Components/Dropdown';
import { useNavigate } from "react-router-dom";

import icon1 from "./HomepageIcons/Chevron.svg"
import icon2 from "./HomepageIcons/Listen.svg"
import icon3 from "./HomepageIcons/Sentiment Avatars.svg"
import icon4 from "./HomepageIcons/Vector 1.svg"
import icon5 from "./HomepageIcons/discord podcast.svg"
import icon6 from "./HomepageIcons/Podcaster.svg"

const HomepageContainer = () => {
    const [scheduledPodcasts, setScheduledPodcasts] = useState([])
    const [show, setShow] = useState(false)
    const [selectedPodcast, setSelectedPodcast] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                // Fetch podcast details
                const response = await getScheduledPodcasts();
                setScheduledPodcasts(response?.data?.Data)

            } catch (error) {
                console.error("Error fetching podcasts:", error); // Error handling
            } finally {
                // setLoading(false); // End loading after the fetch is complete
            }
        };
        fetchPodcasts();

    }, [])

    function formatDate(dateString) {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };

        const date = new Date(dateString);
        return date.toLocaleString("en-US", options);
    }
    const handlePodcastClick = (value) => {
        setShow(true)
        console.log("value", value)
        setSelectedPodcast(value);
    }
    const handleClose = () => {
        setShow(false)
    }

    const handleClickCreate = () => {
        navigate("/schedules");
    }

    const data = [10000, 10000, 13000, 15000, 18000, 14000];
    const labels = ['Facebook', 'Instagram', 'LinkedIn', 'YouTube', 'Spotify', 'Apple Podcast'];
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFC133', '#8E44AD'];

    return (
        <>
            <div id='homepage-container'>
                <div className='homepage-status'>
                    <div className='status-text'>
                        <div className='status-texty'>
                            <div className='status-text-item'>
                                <div className='text-item-container'>
                                    <span className='itemTitle'>Total Engagement</span>
                                    <span className='itemValue'>45K</span>
                                    <img className='img-vector' src={icon4}></img>

                                </div>
                                <div className='text-item-container'>
                                    <span className='itemTitle'>Total Reach</span>
                                    <span className='itemValue'>120K</span>
                                    <img className='img-listen' src={icon2}></img>
                                </div>
                            </div>
                            <div className='status-text-item'>
                                <div className='text-item-container'>
                                    <span className='itemTitle'>Sentiment Score</span>
                                    <span className='itemValue'>75%</span>
                                    <img className='img-sentiment' src={icon3}></img>
                                </div>
                                <div className='text-item-container'>
                                    <span className='itemTitle'>Overall Growth</span>
                                    <span className='itemValue'>+10%</span>
                                    <img className='img-chevron' src={icon1} alt="Status Icon" />

                                </div>
                            </div>
                            <div className='status-text-item'>
                                <div className='text-item-container2'>
                                    <div className='top-podcast-details'>
                                        <span className='itemTitle'>Top Performing Podcast</span>
                                        <span className='item-Toppodcast'>Chill Vibes</span>
                                    </div>
                                    <div className='top-podcast-details'>
                                        <span className='itemValue'>40%</span>
                                        <span className='itemTitle'>Of total</span>
                                        <span className='itemTitle'>Engagement</span>

                                    </div>
                                    <img className="img-podcast" src={icon5} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='upcoming-events'>
                        <span className='upcoming-event-head'>Upcoming</span>
                        <div className='upcoming-content'>
                            {scheduledPodcasts?.length > 0 ? (
                                scheduledPodcasts?.map((podcast, index) => (
                                    <div className='upcoming-content-item' onClick={() => handlePodcastClick(podcast)}>
                                        <span className='upcoming-event-Title'>{podcast?.Title}</span>
                                        <div className='upcoming-event-info'>
                                            <div className='upcoming-event-data'>
                                                <span>  Host: {podcast?.Host}</span>
                                                <span>  Date/Time: {formatDate(podcast.StartDttm)}</span>
                                            </div>
                                            <div className='upcoming-event-data'>
                                                <span>Guest:</span>
                                                {JSON.parse(podcast?.Guests)?.map((guest, index) => (
                                                    <span key={index} className="guests">
                                                        {guest}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='upcoming-content-empty'>
                                    <div className='content-empty-container'>
                                        <div className='content-empty-avatar'>
                                            <img className='empty-avatar' src={icon6} alt="Avatar" />
                                        </div>
                                        <span className='content-empty-text'>No upcoming podcasts scheduled.</span>
                                    </div>
                                    <div className='content-empty-footer'>
                                        <button onClick={() => handleClickCreate()}>
                                            <span>Add a New Podcast</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='homepage-chart'>
                    <div className='bar-chart'>
                        <div className='chartHead'>
                            <span className='bar-chart-title'>Top Performing Podcast</span>
                        </div>
                        <div className='chart'>

                            <BarChart
                                xAxis={[{
                                    scaleType: 'band', data: ['Facebook', "Instagram", 'LinkedIn', 'Youtube', 'Spotify', 'ApplePodcast'],
                                    colorMap: {
                                        type: 'ordinal',
                                        values: ['Facebook', "Instagram", 'LinkedIn', 'Youtube', 'Spotify', 'ApplePodcast'],
                                        colors: ['#1877f2', '#780CFF', '#0A66C2', '#ED1D24', '#1ED760', '#D56DFB'],
                                    }
                                }]}
                                series={[{
                                    data: [10000, 10000, 13000, 15000, 18000, 14000]
                                }]}
                                width={600}
                                height={400}
                                borderRadius={10}
                                leftAxis={null}
                            />
                        </div>
                    </div>
                    <div className='pie-chart'>
                        <div className='chartHead'>
                            <span className='pie-chart-title'>Overall Sentiment Analysis</span>
                            <Dropdowncomponent />
                        </div>
                        <div className='chart'>
                            <div className='PieChart'>
                                <PieChart
                                    // width={600}
                                    // height={400}
                                    width={600}
                                    height={400}
                                    // sx={{border: '5px solid white'}}
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 8, color: 'rgba(237,29,36,0.6)',},
                                                { id: 1, value: 71, color: 'rgba(30,215,96,0.6)',},
                                                { id: 2, value: 21, color: 'rgba(255,154,40,0.6)',}
                                            ],
                                            type: 'pie',
                                            innerRadius: 90,
                                            outerRadius: 160,
                                            paddingAngle: 3,
                                            cornerRadius: 15,
                                            startAngle: 0,
                                            endAngle: 360,
                                            cx: 300,
                                            cy: 200,
                                        }
                                    ]}
                                />
                                <img className='pie-img-sentiment' src={icon3}></img>
                            </div>
                            <div className='chart-notation'>
                                <div className='chart-notation-item'>
                                    <div style={{
                                        backgroundColor: 'rgba(30,215,96,0.6)',
                                        borderRadius: "50%",
                                        width: '20px',
                                        height: '20px',
                                    }}></div>
                                    <span>Positive</span>
                                </div>
                                <div className='chart-notation-item'>
                                    <div style={{
                                        backgroundColor: 'rgba(255,154,40,0.6)',
                                        borderRadius: "50%",
                                        width: '20px',
                                        height: '20px',
                                    }}></div>
                                    <span>Neutral</span>
                                </div>
                                <div className='chart-notation-item'>
                                    <div style={{
                                        backgroundColor: 'rgba(237,29,36,0.6)',
                                        borderRadius: "50%",
                                        width: '20px',
                                        height: '20px',
                                    }}></div>
                                    <span>Negative</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <HomepodcastPreview show={show} data={selectedPodcast} onClose={handleClose} />
        </>
    )
}

export default HomepageContainer
