import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

export const getShows = async (name = '', page = 1, limit = 8) => {
    try {
        const path = "shows/list";
        // Add page and limit to the query parameters
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}&ShowName=${name}&page=${page}&limit=${limit}`);
        return response;
    } catch (error) {
        console.error("Error fetching shows:", error);
        return 'Unable to fetch scheduled podcasts';
    }
};


export const getShowDetails = async (ShowID) => {
    try {
        const path = "shows/details";
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}&ShowID=${ShowID}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const trimVideo = async (data) => {
    try {
        const path = "trimVideo";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to fetch trimmed video presigned url';
    }
};

export const fetchVideoFrames = async (data) => {
    try {
        const path = "fetchVideoFrames";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to save trimmed video presigned url';
    }
};

export const createShow = async (data) => {
    try {
        const path = "show";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const updateShow = async (data) => {
    try {
        const path = "show";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const updateUserProfile = async (data) => {
    try {
        const path = "users";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Error occurred during user profile updation';
    }
};
export const handleUserSignup = async (data) => {
    try {
        console.log("Signup data:", data)
        const path = "users";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        console.log('Error while handling user sign up: ', error)
        return 'Error occurred during signup';
    }
};
export const getUserProfileDetails = async (type, value = localStorage.getItem('user_id')) => {
    try {
        const path = "users";
        const response = await axios.get(dyrectApi + path + `?${type}=${value}`);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
};
export const emailValidation = async (data) => {
    try {
        const path = "check/email";
        const response = await axios.get(dyrectApi + path + `?Email=${data}`);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
};
export const emailInvite = async (data) => {
    try {
        const path = "send/invite";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
};

export const updateShowDetails = async (data) => {
    try {
        const path = "shows/details";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
}

export const deleteShow = async (ShowID) => {
    try {
        const path = `podcasts/recorded?ShowID=${ShowID}`;
        const response = await axios.delete(dyrectApi + path);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
}