import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import './Pages.css';
import { Button, Tooltip, IconButton } from '@mui/material';
import { getScheduledPodcasts, deletePodcast } from '../Services/SchedulesApi';
import Loader from '../Components/Loader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningDialog from '../Components/WarningDialog';
import { getUserProfileDetails } from '../Services/ShowsApi'

const Schedules = () => {
    const navigate = useNavigate();
    const [podcasts, setPodcasts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);
    const [selectedPodcast, setSelectedPodcast] = useState(null); // State to hold the selected podcast
    const [userProfile, setUserProfile] = useState({ firstName: '', lastName: '' });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulate loading for 3 seconds
    }, []);

    // Fetch the podcasts data and update the state
    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                // Fetch podcast details
                const response = await getScheduledPodcasts(); // API call to fetch podcasts
                setPodcasts(response?.data?.Data); // Update podcasts data

                // Fetch user profile details
                const profileResponse = await getUserProfileDetails("UserID");
                const profileData = profileResponse?.data?.body?.Data?.[0] || {};
                setUserProfile({
                    firstName: profileData.FirstName || '',
                    lastName: profileData.LastName || '',
                });
                localStorage.setItem('fullName', `${profileData.FirstName} ${profileData.LastName}`)
            } catch (error) {
                console.error("Error fetching podcasts:", error); // Error handling
            } finally {
                setLoading(false); // End loading after the fetch is complete
            }
        };

        fetchPodcasts();
    }, []);

    // Handle button click to navigate to the create schedule page
    const handleSchedule = () => {
        navigate('/schedules/create');  // Navigate to create page
    };

    // Handle click on an existing podcast to edit
    const handleEditPodcast = (podcast) => {
        navigate('/schedules/create', { state: { podcast } }); // Pass selected podcast data to the create/edit form
    };

    const handleDelete = (scheduleId, podcast, event) => {
        event.stopPropagation();
        setSelectedScheduleId(scheduleId);
        setSelectedPodcast(podcast); // Set the selected podcast
        setOpenDialog(true);
    };

    const confirmDelete = async () => {
        if (selectedScheduleId) {
            try {
                await deletePodcast(selectedScheduleId); // Call to delete the podcast
                setPodcasts(podcasts.filter(podcast => podcast.ScheduleID !== selectedScheduleId));
            } catch (error) {
                console.error("Error deleting podcast:", error);
            }
        }
        setOpenDialog(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedScheduleId(null);
        setSelectedPodcast(null); // Clear the selected podcast
    };

    return (
        <div className='theme-bg clr-white height-100' style={{ display: "flex" }}>
            <SideBar />
            <div className='container overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="col-6 mb-4">
                                <h2>Podcast Schedules</h2>
                            </div>
                            <div className="col-6">
                                {/* <Button className="btn fl-r schedule-btn">
                                    <span className='text-trans-none'>Record</span>
                                </Button> */}
                                <Button
                                    className="btn fl-r schedule-btn"
                                    style={{ marginRight: "10px" }}
                                    onClick={handleSchedule}
                                >
                                    <span className='text-trans-none'>Schedule</span>
                                </Button>
                            </div>
                        </div>
                        {loading ? (
                            <Loader message="Fetching your schedules" />
                        ) : (
                            <div className="row justify-content-center" style={{ height: '80vh', overflowY: 'auto' }}>
                                <div className="col-md-12">
                                    {podcasts?.length === 0 ? (
                                        <div className="col-12" style={{ textAlign: 'center' }}>
                                            <h4>No podcasts Found</h4>
                                        </div>
                                    ) :
                                        (
                                            podcasts?.map((podcast, index) => (
                                                <div
                                                    key={index}
                                                    className="card mb-3 pointer"
                                                    style={{
                                                        backgroundColor: "rgb(25 25 62)",
                                                        color: "#fff",
                                                        borderRadius: "10px",
                                                        border: "none"
                                                    }}
                                                    onClick={() => handleEditPodcast(podcast)}
                                                >
                                                    <div className="row card-body">
                                                        <div className='col-6'>
                                                            <h5 className="card-title" style={{ fontSize: "20px" }}>{podcast.Title}</h5>
                                                        </div>
                                                        <div className='col-3'>
                                                            <p className="card-text">
                                                                <strong>Host:</strong> {podcast.Host}<br />
                                                                <strong>Date and Time:</strong> {new Date(podcast.StartDttm).toLocaleString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric', })}<br />
                                                            </p>
                                                        </div>
                                                        <div className="col-2">
                                                            <p className="card-text guests-list">
                                                                <strong>Guests: </strong>
                                                                <span className='guests-list'>
                                                                    {JSON.parse(podcast?.Guests || "[]")?.slice(0, 2).map((guest, idx) => (
                                                                        <span key={idx}>
                                                                            {guest}
                                                                            {idx < 1 && ', '}
                                                                        </span>
                                                                    ))}
                                                                    {JSON.parse(podcast?.Guests || "[]")?.length > 2 && (
                                                                        <Tooltip
                                                                            title={JSON.parse(podcast?.Guests || "[]")?.slice(2).join(", ")}
                                                                            arrow
                                                                            placement="top"
                                                                        >
                                                                            <span className="more-guests">
                                                                                {` +${JSON.parse(podcast?.Guests || "[]")?.length - 2}`}
                                                                            </span>
                                                                        </Tooltip>
                                                                    )}
                                                                </span>
                                                            </p>
                                                        </div>

                                                        <div className='col-1'>
                                                            {!podcast.guest ? (
                                                                <IconButton
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={(event) => handleDelete(podcast.ScheduleID, podcast, event)}
                                                                >
                                                                    <DeleteOutlineIcon fontSize="small" style={{ color: '#fff', fontSize: '1.5rem' }} />
                                                                </IconButton>
                                                            ) : (
                                                                <div></div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <WarningDialog
                open={openDialog}
                onClose={handleDialogClose}
                onConfirm={confirmDelete}
                title="Delete Schedule"
                description={selectedPodcast?.Title ? `Are you sure you want to delete this schedule: ${selectedPodcast.Title}?` : 'Are you sure you want to delete this schedule?'}
            />
        </div>
    );
};

export default Schedules;