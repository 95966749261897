import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Slider,
  Typography,
  Button,
  TextField,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  createBanner,
  createMeetingBanner,
  deleteBanner,
  listBanners,
  updateMeetingBanner,
} from "../Services/BannersApi";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBannerFont, setBanner, setBannerColor } from "../redux/bannerSlice";
import { bannerTheme } from "../utils/constant";
import Accordion from "../Components/Accordion";

const BannerPanel = ({ meeting }) => {
  const { publish } = usePubSub("UPDATE_BANNER");
  const dispatch = useDispatch();
  const mMeeting = useMeeting();
  const savedBgColor = localStorage.getItem("bgColor") || "#00002910";
  const savedBannerText =
    localStorage.getItem("bannerText") || "This is a sample banner.";
  const savedFontSize = Number(localStorage.getItem("fontSize")) || 16;

  const [bgColor, setBgColor] = useState(savedBgColor);
  const [bannerText, setBannerText] = useState(savedBannerText);
  const [fontSize, setFontSize] = useState(savedFontSize);
  const [bannerData, setBannerData] = useState([]); // Start with an empty list
  const [newBanner, setNewBanner] = useState("");
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isCreatingBanner, setIsCreatingBanner] = useState(false);
  const [hoveredBanner, setHoveredBanner] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const meetingId = queryParams.get("meetingId");
  const banner = useSelector((state) => state?.banner);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedFont, setSelectedFont] = useState("Nexa Extra Light");
  const [activeIndex, setActiveIndex] = useState(0);
  const fonts = [
    "Nexa Extra Light",
    "Arial",
    "Times New Roman",
    "Courier New",
    "Verdana",
    "Georgia",
  ];

  // Load banners when component mounts
  useEffect(() => {
    fetchBanners();
  }, []);

  // Save state to localStorage
  useEffect(() => {
    localStorage.setItem("bgColor", bgColor);
    localStorage.setItem("bannerText", bannerText);
    localStorage.setItem("fontSize", fontSize);
  }, [bgColor, bannerText, fontSize]);

  const handleBannerClick = async (banner) => {
    dispatch(setBanner(banner));
    if (mMeeting?.isRecording) {
      const createBannerData = {
        BannerID: banner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: bgColor,
        FontSize: `${fontSize}px`,
        StartTime: getCurrentTimestamp(),
      };
      console.log("createBannerData", createBannerData);
      await createMeetingBanner(createBannerData);
    }
    setSelectedBanner(banner);
    setBannerText(banner.BannerText); // Access BannerText to update state
    const bannerPayload = {
      BannerID: banner?.BannerID,
      message: banner.BannerText, // Send only the BannerText to the publish function
      backgroundColor: bgColor,
      fontSize: `${fontSize}px`,
      bannerStyle: activeIndex,
      font: selectedFont,
    };
    publish(bannerPayload, { persist: true });
  };

  function getCurrentTimestamp() {
    // const now = new Date();
    // const year = now.getFullYear();
    // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    // const day = String(now.getDate()).padStart(2, '0');
    // const hours = String(now.getHours()).padStart(2, '0');
    // const minutes = String(now.getMinutes()).padStart(2, '0');
    // const seconds = String(now.getSeconds()).padStart(2, '0');

    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //sending UTC time instaed of IST
    return new Date().toISOString();
  }

  const fetchBanners = async () => {
    try {
      const response = await listBanners(localStorage.getItem("user_id"));
      if (response) {
        setBannerData(response.data.Data);
      } else {
        setBannerData([]); // Fallback to empty array if data is not valid
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      setBannerData([]); // Fallback to empty array on error
    }
  };

  // const handleColorChange = async (id, selectedColor) => {
  const handleColorChange = async (event) => {
    const selectedColor = event.target.value;
    dispatch(setBannerColor(selectedColor));
    setBgColor(selectedColor);
    // setSelectedId(selectedId === id ? null : id);
    if (mMeeting?.isRecording) {
      const createBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: selectedColor,
        FontSize: `${fontSize}px`,
        StartTime: getCurrentTimestamp(),
        font: selectedFont,
      };
      await createMeetingBanner(createBannerData);
    }
    if (selectedBanner) {
      const bannerPayload = {
        BannerID: selectedBanner?.BannerID,
        message: selectedBanner.BannerText, // Only pass BannerText
        backgroundColor: selectedColor,
        fontSize: `${fontSize}px`,
        bannerStyle: activeIndex,
        font: selectedFont,
      };
      console.log("CallingBannerAPICC");
      publish(bannerPayload, { persist: true });
    }
  };

  const handleFontSizeChange = async (event, newValue) => {
    setFontSize(newValue);
    dispatch(setBannerFont(newValue));
    if (mMeeting?.isRecording) {
      console.log("CallingBannerAPIFF");
      const createBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: bgColor,
        FontSize: `${newValue}px`,
        StartTime: getCurrentTimestamp(),
        font: selectedFont,
      };
      console.log("createBannerData", createBannerData);
      await createMeetingBanner(createBannerData);
    }
    if (selectedBanner) {
      const bannerPayload = {
        BannerID: selectedBanner?.BannerID,
        message: selectedBanner.BannerText, // Only pass BannerText
        backgroundColor: bgColor,
        fontSize: `${newValue}px`,
        bannerStyle: activeIndex,
        font: selectedFont,
      };
      console.log("CallingBannerAPIFF");
      publish(bannerPayload, { persist: true });
    }
  };

  const handleAddNewBanner = async () => {
    if (newBanner.trim()) {
      const bannerDataToAdd = {
        UserID: localStorage.getItem("user_id"), // Use actual UserID
        BannerText: newBanner.trim(),
        BackgroundColor: bgColor,
        bannerStyle: activeIndex,
      };

      const response = await createBanner(bannerDataToAdd); // API call to create banner
      if (response && response.data) {
        // Ensure bannerData is an array before updating it
        fetchBanners();
        setNewBanner("");
        setIsCreatingBanner(false);
      }
    }
  };

  const handleDeleteBanner = async (banner) => {
    // First, delete the banner via the API call
    const response = await deleteBanner(banner?.BannerID); // API call to delete banner
    if (mMeeting?.isRecording) {
      const updateBannerData = {
        BannerID: banner?.BannerID,
        MeetingID: meetingId,
        EndTime: getCurrentTimestamp(),
      };
      console.log("updateBannerData", updateBannerData);
      await updateMeetingBanner(updateBannerData);
    }
    if (response && response.data) {
      // Remove the banner from the local state
      setBannerData(bannerData.filter((item) => item !== banner));

      // If the deleted banner is the selected banner, clear the selection and notify PubSub
      if (selectedBanner === banner) {
        setSelectedBanner(null);

        // Notify PubSub that there is no banner being shown (empty banner payload)
        const bannerPayload = {
          BannerID: null,
          message: "",
          backgroundColor: bgColor,
          fontSize: `${fontSize}px`,
        };
        publish(bannerPayload, { persist: true });
      }
    }
  };

  const handleStopBanner = async () => {
    setSelectedBanner(null);
    if (mMeeting?.isRecording) {
      const updateBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        EndTime: getCurrentTimestamp(),
      };
      console.log("updateBannerData", updateBannerData);
      await updateMeetingBanner(updateBannerData);
    }
    const bannerPayload = {
      BannerID: null,
      message: "",
      backgroundColor: bgColor,
      fontSize: `${fontSize}px`,
    };
    publish(bannerPayload, { persist: true });
  };

  const toggleBannerVisibility = (banner) => {
    const isCurrentlySelected = selectedBanner === banner;
    if (isCurrentlySelected) {
      handleStopBanner();
    } else {
      handleBannerClick(banner);
    }
  };

  const handleSelectTheme = async (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    // dispatch(setBannerTheme(index));
    if (banner) {
      console.log("happeningHere");
      const selectedBanner = banner?.banner;
      const bannerPayload = {
        BannerID: selectedBanner?.BannerID,
        message: selectedBanner?.BannerText,
        backgroundColor: bgColor,
        fontSize: `${fontSize}px`,
        bannerStyle: index,
        font: selectedFont,
      };

      publish(bannerPayload, { persist: true });
    }
  };

  const handleFontTheme = (event) => {
    setSelectedFont(event.target.value);
    console.log(`Font selected: ${event.target.value}`);

    const bannerPayload = {
      BannerID: selectedBanner?.BannerID,
      message: selectedBanner?.BannerText
        ? selectedBanner?.BannerText
        : localStorage.getItem("bannerText"), // Only pass BannerText
      backgroundColor: bgColor,
      fontSize: `${fontSize}px`,
      bannerStyle: activeIndex,
      font: event.target.value,
    };
    console.log("CallingBannerAPIFF");
    publish(bannerPayload, { persist: true });
  };

  return (
    <Box
      color="white"
      className="px-3"
      style={{ maxHeight: "70vh", overflowY: "auto", paddingTop: "5px" }}
    >
      <Accordion title="Your Banners">
        <div
          // style={{ maxHeight: "55vh", maxHeight: "45vh", overflowY: "auto" }}
        >
          {bannerData?.map((banner, index) => (
            <div
              key={index}
              className="bannerData pointer"
              onMouseEnter={() => setHoveredBanner(banner)}
              onMouseLeave={() => setHoveredBanner(null)}
              style={{
                position: "relative",
                backgroundColor:
                  selectedBanner === banner ? "#ffffff50" : "#ffffff10",
              }}
            >
              <div
                onClick={() => handleBannerClick(banner)} // Pass the banner to handleBannerClick
                style={{
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  position: "relative",
                  width: "90%",
                }}
              >
                {banner.BannerText} {/* Render the BannerText property */}
              </div>
              <IconButton
                onClick={() => handleDeleteBanner(banner)} // Handle delete of the specific banner
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "red",
                }}
              >
                <DeleteIcon />
              </IconButton>
              {hoveredBanner === banner && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleBannerVisibility(banner)} // Toggle visibility
                >
                  {selectedBanner === banner ? (
                    <>
                      <VisibilityOffIcon style={{ marginRight: "5px" }} />
                      Hide
                    </>
                  ) : (
                    <>
                      <VisibilityIcon style={{ marginRight: "5px" }} />
                      Show
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="my-2">
          {!isCreatingBanner ? (
            <Typography
              variant="h6"
              onClick={() => setIsCreatingBanner(true)}
              style={{
                cursor: "pointer",
                width: "100%",
                textAlignLast: "center",
                padding: "2%",
                borderRadius: "10px",
              }}
              className="schedule-btn"
            >
              Add New Banner
            </Typography>
          ) : (
            <div>
              <TextField
                label="New Banner"
                variant="filled"
                fullWidth
                value={newBanner}
                onChange={(e) => setNewBanner(e.target.value)}
                inputProps={{ maxLength: 100 }}
                helperText={`${newBanner.length}/100 characters`}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewBanner}
                style={{ marginTop: "10px", textTransform: "none" }}
              >
                Add Banner
              </Button>
              <Button
                variant="outlined"
                className="cancel-btn"
                onClick={() => setIsCreatingBanner(false)}
                style={{
                  marginTop: "10px",
                  textTransform: "none",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Accordion>
      <Accordion title="Banner Design">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "64px",
            padding: "12px 0px",
            alignSelf: "stretch",
          }}
        >
          <label
            htmlFor="colorPicker"
            style={{ verticalAlign: "top", fontSize: "14px" }}
          >
            Brand Color:{" "}
          </label>
          <input
            id="colorPicker"
            type="color"
            value={bgColor}
            onChange={(e) => handleColorChange(e)}
            style={{
              padding: "0",
              border: "none",
              height: "30px",
              width: "30px",
            }}
          />

          {/* {bannerColor.map((box) => (
          <div
            key={box.id}
            onClick={() => handleColorChange(box.id, box.color)}
            style={{
              height: selectedId === box.id ? "35px" : "25px",
              width: selectedId === box.id ? "35px" : "25px",
              backgroundColor: box.color,
              borderRadius: "5px",
              transition: "all 0.2s ease",
              cursor: "pointer",
            }}
          />
        ))} */}
        </div>

        {/* Using banner font size default 15px */}
        {/* <Divider style={{ borderColor: "white" }} className="my-2" /> */}
        <Typography gutterBottom style={{ fontSize: "14px" }}>
          Font Theme
        </Typography>
        <div style={{ marginTop: "3%" }}>
          {/* Dropdown to select font */}
          <FormControl fullWidth>
            {/* <InputLabel id="font-select-label">Select a Font</InputLabel> */}
            <Select
              labelId="font-select-label"
              value={selectedFont}
              onChange={handleFontTheme}
              // label="Select a Font"
              style={{
                fontFamily: selectedFont || "Arial", // Default to Arial if no font is selected
                fontSize: "16px",
                border: "1px solid white",
              }}
            >
              <MenuItem value="" disabled>
                Select a Font
              </MenuItem>
              {fonts.map((font, index) => (
                <MenuItem key={index} value={font} style={{ fontFamily: font }}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* <Divider style={{ borderColor: "white" }} className="my-2" /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "64px",
            padding: "12px 0px",
            alignSelf: "stretch",
          }}
        >
          <div>Font Size</div>
          <div>{fontSize}</div>
        </div>
        <Slider
          value={fontSize}
          onChange={handleFontSizeChange}
          aria-labelledby="font-size-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}px`}
          min={15}
          max={40}
          step={1}
          style={{
            marginLeft: "2%",
          }}
        />
        <div style={{ marginTop: "10px", fontSize: "14px" }}>
          <span>Banner Theme</span>
          <div className="banner-theme">
            {bannerTheme?.map((item, index) => (
              <div
                className={`banner-theme-item ${
                  activeIndex === index ? "active" : ""
                }`}
                key={index}
                onClick={() => handleSelectTheme(index)}
              >
                <div
                  style={{
                    ...item,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "80px",
                    wordBreak: "break-word",
                  }}
                >
                  <span>{item.label}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Accordion>
    </Box>
  );
};

export default BannerPanel;
