import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import { a11yProps, CustomTabPanel } from "./CustomTabPanel"; // You can use your existing CustomTabPanel
import MeetingBackgroundPanel from "./MeetingBackgroundPanel";
import MeetingOverlayPanel from "./MeetingOverlayPanel";

const CanvasSettingsTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        //   height: "64px",
        //   padding: "12px 0px",
          alignSelf: "stretch",
          gap: "2px",
          margin: '10px'
        }}
      >
        <Button
          variant={value === 0 ? "contained" : ""}
          onClick={(e) => handleChange(e, 0)}
          sx={{ textTransform: "none", flex: 1 ,background: value === 0 ? 'rgba(71, 45, 219, 0.50)' : "",color: 'white',fontSize:'15px'}} // Makes buttons equal width
        >
          Background
        </Button>
        <Button
          variant={value === 1 ? "contained" : ""}
          onClick={(e) => handleChange(e, 1)}
          sx={{ textTransform: "none", flex: 1 ,background: value === 1 ? 'rgba(71, 45, 219, 0.50)' : "",color: 'white',fontSize:'15px'}} // Makes buttons equal width
        >
          Overlay
        </Button>
      </div>

            <CustomTabPanel value={value} index={0}>
                <MeetingBackgroundPanel />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <MeetingOverlayPanel />
            </CustomTabPanel>

        </Box>
    );
};

export default CanvasSettingsTabs;
