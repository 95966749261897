import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ArrowOutwardTwoToneIcon from '@mui/icons-material/ArrowOutwardTwoTone';
import dummyImage from "./HomepageIcons/image 7.svg"
import { useNavigate } from "react-router-dom";

const HomepodcastPreview = ({ show, data, onClose }) => {

  const navigate = useNavigate();

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  const sanitizeAgenda = (text) => {
    return text?.replace(/<p[^>]*>|<\/p>/g, '');
  };

  const handleClick = () => {
    navigate("/shows");
  };
  return (
    <Modal
      open={show}
    >
      <Box id='event-details-box'>
        <div className='event-details-container'>
          <div className='event-details-head'>
            <span className='event-head-title'>Upcoming Event</span>
            <button onClick={onClose}><CloseTwoToneIcon /></button>
          </div>
          <div className='event-content'>
            <div className='event-title'>
              {data?.Title}
            </div>
            <div className='event-info'>
              <div className='event-data'>
                <span >  Host: {data?.Host}</span>
                <span>  Date/Time: {formatDate(data?.StartDttm)}</span>
              </div>
              <div className='event-data'>
                <span>Guest:</span>
                  {data?.Guests && Array.isArray(JSON.parse(data?.Guests)) && JSON.parse(data.Guests).map((guest) => (
                    <span className='guests'>{guest}</span>
                  ))}
              </div>
            </div>
          </div>
          <div className='event-agenda'>
            <span className='agenda-title'>Agenda</span>
            <span>{sanitizeAgenda(data?.Agenda)}</span>
          </div>
          {/* <div className='event-footer'>
            <button onClick={()=>handleClick()}>
              <span>View More</span>
              <ArrowOutwardTwoToneIcon />
            </button>
          </div> */}
        </div>
      </Box>
    </Modal>
  )
}

export default HomepodcastPreview
