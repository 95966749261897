import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

// Function to create a banner
export const createBanner = async (data) => {
    try {
        const path = "banners";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating banner:", error);
        return 'Unable to create Banner';
    }
};

export const createMeetingBanner = async (data) => {
    try {
        const path = "banners/meeting";  // Specify the endpoint path
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating banner:", error);
        return 'Unable to create Banner';
    }
};

export const updateMeetingBanner = async (data) => {
    try {
        const path = "banners/meeting";  // Specify the endpoint path
        const response = await axios.put(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error updating banner:", error);
        return 'Unable to update Banner';
    }
};



// Function to list all banners for a specific user
export const listBanners = async (userID) => {
    try {
        const path = `banners/list?UserID=${userID}`;
        const response = await axios.get(dyrectApi + path);
        return response;
    } catch (error) {
        console.error("Error fetching banners:", error);
        return 'Unable to fetch banners';
    }
};

// Function to get details of a single banner by BannerID
export const getSingleBanner = async (userID, bannerID) => {
    try {
        const path = `banners/details?UserID=${userID}&BannerID=${bannerID}`;
        const response = await axios.get(dyrectApi + path);
        return response;
    } catch (error) {
        console.error("Error fetching banner details:", error);
        return 'Unable to fetch banner details';
    }
};

// Function to delete a banner by BannerID
export const deleteBanner = async (bannerID) => {
    try {
        const path = `banners?BannerID=${bannerID}`;
        const response = await axios.delete(dyrectApi + path);
        return response;
    } catch (error) {
        console.error("Error deleting banner:", error);
        return 'Unable to delete banner';
    }
};

export const getLogos = async () => {
    try {
        const path = `logos/list?UserID=${localStorage.getItem('user_id')}`;
        const response = await axios.get(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the data received from the API
    } catch (error) {
        console.error("Error fetching logos:", error);
        return 'Unable to fetch logos';
    }
};

export const deleteLogo = async (logoId) => {
    try {
        const path = `logos?LogoID=${logoId}`;
        const response = await axios.delete(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the response after deletion
    } catch (error) {
        console.error("Error deleting logo:", error);
        return 'Unable to delete logo'; // Error handling
    }
};

// Function to create a logo
export const createLogo = async (data) => {
    try {
        const path = "logos";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating banner:", error);
        return 'Unable to create Banner';
    }
};

// Function to create a banner
export const createMeetingLogo = async (data) => {
    try {
        const path = "meeting/logos";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating banner:", error);
        return 'Unable to create Banner';
    }
};

export const updateMeetingLogo = async (data) => {
    try {
        const path = "meeting/logos";  // Specify the endpoint path
        const response = await axios.put(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error updating banner:", error);
        return 'Unable to update Banner';
    }
};

export const getStageBackgrounds = async () => {
    try {
        const path = `stage/background/list?UserID=${localStorage.getItem('user_id')}`;
        const response = await axios.get(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the data received from the API
    } catch (error) {
        console.error("Error fetching stage backgrounds:", error);
        return 'Unable to fetch stage backgrounds';
    }
}

export const deleteStageBackground = async (logoId) => {
    try {
        const path = `stage/background?StageBackgroundID=${logoId}`;
        const response = await axios.delete(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the response after deletion
    } catch (error) {
        console.error("Error deleting logo:", error);
        return 'Unable to delete stage background'; // Error handling
    }
};

export const createStageBackground = async (data) => {
    try {
        const path = "stage/background";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating banner:", error);
        return 'Unable to create Banner';
    }
};

export const getStageOverlays = async () => {
    try {
        const path = `stage/overlay/list?UserID=${localStorage.getItem('user_id')}`;
        const response = await axios.get(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the data received from the API
    } catch (error) {
        console.error("Error fetching stage overlay:", error);
        return 'Unable to fetch stage overlays';
    }
}

export const deleteStageOverlays = async (overlayId) => {
    try {
        const path = `stage/overlay?StageOverlayID=${overlayId}`;
        const response = await axios.delete(dyrectApi + path, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data; // Return the response after deletion
    } catch (error) {
        console.error("Error deleting overlay:", error);
        return 'Unable to delete stage overlay'; // Error handling
    }
};

export const createStageOverlays = async (data) => {
    try {
        const path = "stage/overlay";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating overlay:", error);
        return 'Unable to create overlay';
    }
};