import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useState } from "react";
import ConfirmBox from "./ConfirmBox";
import { Avatar } from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useDispatch, useSelector } from "react-redux";
import { removeData } from '../../redux/dataSlice';

const RequestedEntries = () => {

  const dispatch = useDispatch()
  const data = useSelector((state) => state?.data)

  // const handleOnEntryRequested = ({ participantId, name, allow, deny }) => {
  //   setRequestedEntries((s) => [...s, { participantId, name, allow, deny }]);
  // };
  // const handleOnEntryResponded = (participantId, decision) => {
  // setRequestedEntries((s) =>
  //   s.filter((p) => p.participantId !== participantId)
  // };

  // useMeeting({
  //   onEntryRequested: handleOnEntryRequested,
  //   onEntryResponded: handleOnEntryResponded,
  // });


  const handleOnEntryResponded = (participantId, decision) => {
    dispatch(removeData({ participantId }));
  };

  useMeeting({
    onEntryResponded: handleOnEntryResponded,
  });

  useEffect(() => {
  }, [data])

  // return requestedEntries.map(({ participantId, name, allow, deny }) => {
  return (
    // <ConfirmBox
    //   key={`entry_request_${participantId}`}
    //   {...{
    //     successText: "Allow",
    //     rejectText: "Deny",
    //     open: true,
    //     onReject: deny,
    //     onSuccess: allow,
    //     title: `${name} wants to join`,
    //     subTitle: `Click allow button to let the participant join the meeting.`,
    //   }}
    // />
    <div>
      {(data?.item_id)?.length === 0 ? (
        <span style={{
          color: 'white',
        }}>No requests at the moment.</span>
      ) : (
        <>
          <span style={{
            color: 'white',
          }}>Participants Requesting to Join</span><br />
          <ul style={{ listStyle: "none", padding: 0 }}>
            {/* {requestedEntries?.map(({ participantId, name, allow, deny }) => ( */}
            {(data?.item_id)?.map(({ participantId, name, allow, deny }) => (
              <li
                key={`entry_request_${participantId}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                  marginTop: "8px",
                  padding: "8px",
                  borderRadius: "5px",
                  backgroundColor: "#3D3C4E"
                }}
              >
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: '8px'
                }}>
                  <Avatar
                    variant={"rounded"}
                    style={{
                      color: "#000029",
                      backgroundColor: "#BDBDBD",
                      fontSize: "20px"
                    }}
                  >
                    {name?.charAt(0)}
                  </Avatar>
                  <span style={{
                    color: "white",
                    fontSize: "16px"
                  }}>{name}</span>

                </div>
                <div>
                  <button
                    onClick={() => {
                      allow();
                      handleOnEntryResponded(participantId);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: 'inherit',
                      color: 'inherit',
                      border: 'none'
                    }}
                  >
                    <CheckCircleRoundedIcon sx={{ color: "#66bb6a" }} />
                  </button>
                  <button
                    onClick={() => {
                      deny();
                      handleOnEntryResponded(participantId);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: 'inherit',
                      color: 'inherit',
                      border: 'none',
                      cursor: "pointer",
                    }}
                  >
                    <CancelRoundedIcon sx={{ color: "#ef5350" }} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
//   )
// };

export default RequestedEntries;
