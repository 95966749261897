import {
  useMeeting,
  useParticipant,
  usePubSub,
} from "@videosdk.live/react-sdk";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useMeetingAppContext } from "../../MeetingAppContextDef";
import hark from "hark";
import { isSendRecvMode, meetingModes } from "../../CONSTS";

const ParticipantAudioPlayer = ({
  participantId,
  handleOnSpeaking,
  handleOnStoppedSpeaking,
  handleOnVolumeStats,
  harkEnabled,
}) => {
  const {
    micOn,
    micStream,
    isLocal,
    consumeMicStreams,
    stopConsumingMicStreams,
  } = useParticipant(participantId);

  const { selectedOutputDeviceId } = useMeetingAppContext();
  const audioPlayer = useRef();

  // useEffect(() => {
  //   if (!isLocal) {
  //     consumeMicStreams();
  //     return () => {
  //       stopConsumingMicStreams();
  //     };
  //   }
  // }, []);

  useEffect(() => {
    if (!isLocal && audioPlayer.current && micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      if (harkEnabled) {
        let harkObj = hark(mediaStream, {});
        harkObj.setInterval(50);
        harkObj.setThreshold(-40);
        harkObj.on("speaking", () => {
          handleOnSpeaking(participantId);
        });

        harkObj.on("volume_change", (currentVolume, threshold) => {
          handleOnVolumeStats(participantId, currentVolume, threshold);
        });

        harkObj.on("stopped_speaking", () => {
          handleOnStoppedSpeaking(participantId);
        });
      }

      audioPlayer.current.srcObject = mediaStream;
      try {
        audioPlayer.current.setSinkId(selectedOutputDeviceId);
      } catch (error) {
        console.log("error", error);
      }
      audioPlayer.current.play().catch((err) => {
        if (
          err.message ===
          "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"
        ) {
          console.error("audio" + err.message);
        }
      });
    } else {
      audioPlayer.current.srcObject = null;
    }
  }, [micStream, micOn, isLocal, participantId, selectedOutputDeviceId]);

  return <audio autoPlay playsInline controls={false} ref={audioPlayer} />;
};

const ParticipantsAudioPlayer = () => {
  const mMeeting = useMeeting();
  const pubsub = usePubSub("__activeSpeaker");
  const { harkEnabled } = useMeetingAppContext();

  const participants = mMeeting?.participants;

  const outputRef = useRef([]);

  let activeSpeakers = [];
  let activeSpeakersInfo = {};

  let bufferSize = 16; // based on setInterval for hark => 50*16 = ~800ms
  useEffect(() => {
    console.log("hark enabled?", harkEnabled);
    if (harkEnabled) {
      function findDominentSpeaker(data) {
        let dominentSpeakerId = null;
        let dominentSpeakerInfo = {};
        if (data.length === 0)
          return { dominentSpeakerId, dominentSpeakerInfo };

        let uniqueSpeakerData = {};
        for (let i = 0; i < data.length; i++) {
          const info = data[i];
          if (uniqueSpeakerData[info.peerId]) {
            uniqueSpeakerData[info.peerId].end = info.end;
            uniqueSpeakerData[info.peerId].volumeStats.concat(info.volumeStats);
          } else {
            uniqueSpeakerData[info.peerId] = info;
          }
        }

        // calculate intensity
        let intensityMap = {};
        let highIntesity = Infinity;
        for (let participantId in uniqueSpeakerData) {
          const volumeStats = uniqueSpeakerData[participantId].volumeStats;
          const intensity =
            volumeStats.reduce((acc, stat) => acc + stat.volumeLevel, 0) /
            volumeStats.length;
          intensityMap[participantId] = intensity;
          if (intensity < highIntesity) {
            highIntesity = intensity;
            dominentSpeakerId = participantId;
            dominentSpeakerInfo = uniqueSpeakerData[participantId];
            dominentSpeakerInfo.volume = intensity;
          }
        }
        return { dominentSpeakerId, dominentSpeakerInfo };
      }
      const interval = setInterval(() => {
        let data = outputRef.current;
        outputRef.current = [];
        // console.log("processing data", data, outputRef.current);
        let { dominentSpeakerId, dominentSpeakerInfo } =
          findDominentSpeaker(data);
        // console.log({ dominentSpeakerId, dominentSpeakerInfo });
        if (dominentSpeakerId) {
          console.log("Publishing...", {
            dominentSpeakerInfo,
          });
          pubsub.publish(
            "active speaker data",
            { persist: false },
            dominentSpeakerInfo
          );
        }
      }, 800);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const resetActiveSpeakerInfo = (participantId) => {
    if (!activeSpeakers.includes(participantId)) {
      activeSpeakers.push(participantId);
    }
    if (!activeSpeakersInfo[participantId]) {
      activeSpeakersInfo[participantId] = {};
    }
    activeSpeakersInfo[participantId].start = new Date();
    activeSpeakersInfo[participantId].end = null;
    activeSpeakersInfo[participantId].volumeStats = [];
    activeSpeakersInfo[participantId].peerId = participantId;
    activeSpeakersInfo[participantId].peerName =
      participants.get(participantId)?.displayName;
  };

  const addToProcessActiveSpeakerInfo = (participantId) => {
    activeSpeakersInfo[participantId].end = new Date();
    let data = activeSpeakersInfo[participantId];

    // push to outputref for future purposes
    outputRef.current.push(data);

    // find index of active speaker
    let index = activeSpeakers.findIndex((pid) => {
      return pid == participantId;
    });

    // remove from array
    activeSpeakers.splice(index, 1);

    // console.log("remaining speaker", activeSpeakers);
    delete activeSpeakersInfo[participantId];
  };

  const handleOnSpeaking = (participantId) => {
    // console.log("speaking", participantId);
    activeSpeakers.push(participantId);
    resetActiveSpeakerInfo(participantId);
  };

  const handleOnStoppedSpeaking = (participantId) => {
    // console.log("stopped speaking", participantId);
    if (activeSpeakers.includes(participantId)) {
      // set end for give speaker
      addToProcessActiveSpeakerInfo(participantId);
    }
  };

  const handleOnVolumeStats = (participantId, currentVolume, threshold) => {
    if (activeSpeakers.includes(participantId) && currentVolume != -Infinity) {
      activeSpeakersInfo[participantId].volumeStats.push({
        volumeLevel: parseInt(currentVolume),
        timestamps: new Date().getTime(),
      });
      if (activeSpeakersInfo[participantId].volumeStats.length >= bufferSize) {
        addToProcessActiveSpeakerInfo(participantId);
        resetActiveSpeakerInfo(participantId);
      }
    }
  };

  return participants ? (
    [...participants.values()]
      .filter((participant) => isSendRecvMode(participant.mode))
      .map((participant) => (
        <ParticipantAudioPlayer
          key={`participant_audio_${participant.id}`}
          participantId={participant.id}
          handleOnSpeaking={handleOnSpeaking}
          handleOnStoppedSpeaking={handleOnStoppedSpeaking}
          handleOnVolumeStats={handleOnVolumeStats}
          harkEnabled={harkEnabled}
        />
      ))
  ) : (
    <></>
  );
};

export default ParticipantsAudioPlayer;