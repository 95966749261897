import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bannerTheme: null, banner: null,bannerFont:null,bannerColor:null
}

const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setBannerFont: (state, action) => {
            state.bannerFont=action.payload
        },
        setBanner(state, action) {
            state.banner= action.payload;
        },
        setBannerTheme(state, action) {
            state.bannerTheme= action.payload;
        },
        setBannerColor(state,action){
            state.bannerColor= action.payload;

        }
    }
});
export const { setBannerFont,setBanner,setBannerTheme,setBannerColor } = bannerSlice.actions;
export default bannerSlice.reducer;