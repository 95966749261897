import React, { useState } from "react";
import Chevron from '../Assests/Chevron.svg';
import Uparrow from '../Assests/Uparrow.svg';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const styles = {
    accordion: {
      borderRadius: "9px",
      marginBottom: "8px",
      overflow: "hidden",
      width: "100%",
      minWidth: "100px", // Adjust as needed
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 16px",
      backgroundColor: isOpen ? "rgba(41, 104, 254, 0.50)" : "rgba(255, 255, 255, 0.08)",
      cursor: "pointer",
      fontWeight: "bold",
      // borderBottom: isOpen ? "1px solid #ddd" : "none",
    },
    icon: {
      fontSize: "18px",
      transition: "transform 0.3s ease", // Add rotation animation to the icon
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate the icon
    },
    content: {
      padding: isOpen ? "16px" : "0 16px", // Adjust padding for animation
      backgroundColor: "rgba(255, 255, 255, 0.08)",
      maxHeight: isOpen ? "50vh" : "0", // Adjust max-height for animation
      overflow: "auto",
      transition: "all 0.3s ease", // Smooth transition for max-height and padding
      opacity: isOpen ? 1 : 0, // Fade in/out effect
    },
  };

  return (
    <div style={styles.accordion}>
      <div style={styles.header} onClick={toggleAccordion}>
        <span>{title}</span>
        <span style={styles.icon}>
          {isOpen ? (
            <img className="arrow" src={Uparrow} alt="Arrow" />
          ) : (
            <img className="arrow" src={Chevron} alt="Arrow" />
          )}
        </span>
      </div>
      <div style={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;