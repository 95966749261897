export const RECORDER_MAX_GRID_SIZE = 8;
export const RECORDER_MAX_GRID_SIZE_WITH_SCREENSCHARE_ENABLED = 8;

export const meetingModes = {
  RECV_ONLY: "RECV_ONLY",
  SEND_AND_RECV: "SEND_AND_RECV",
  SIGNALLING_ONLY: "SIGNALLING_ONLY",
  VIEWER: "VIEWER",
  CONFERENCE: "CONFERENCE",
};

export function isSendRecvMode(mode) {
  return (
    mode === meetingModes.CONFERENCE || mode === meetingModes.SEND_AND_RECV
  );
}

export function isViewerMode(mode) {
  return mode === meetingModes.VIEWER || mode === meetingModes.SIGNALLING_ONLY;
}

export function isRecvOnlyMode(mode) {
  return mode === meetingModes.RECV_ONLY;
}

// export const bannerType = [
//   {
//     width: isVisible ? '600px' : '0px',
//     transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
//     borderRadius: '5px',
//   },
//   {
//     marginLeft: '10px',
//   }, {
//     marginLeft: '10px',
//     borderRadius: '20px',
//   }, {
//     width: '650px',
//     clipPath: isVisible
//       ? 'polygon(0% 100%, 98% 100%, 100% 0%, 0% 0%)'
//       : 'polygon(0% 100%, 0% 100%, 0% 0%, 0% 0%)',
//     borderTopRightRadius: '10px',
//   }
// ]
