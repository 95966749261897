import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import { a11yProps, CustomTabPanel } from "./CustomTabPanel"; // Ensure this is correctly implemented
import BannerPanel from "./BannerPanel";
import LogosPanel from "./LogosPanel";

const BannersLogosTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs Section (Optional, if you want to keep it) */}
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs for banners and logos"
        >
          <Tab
            label="Banners"
            {...a11yProps(0)}
            sx={{ textTransform: "none" }}
          />
          <Tab label="Logos" {...a11yProps(1)} sx={{ textTransform: "none" }} />
        </Tabs>
      </Box> */}

      {/* Buttons Section */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        //   height: "64px",
        //   padding: "12px 0px",
          alignSelf: "stretch",
          gap: "2px",
          margin: '10px'
        }}
      >
        <Button
          variant={value === 0 ? "contained" : ""}
          onClick={(e) => handleChange(e, 0)}
          sx={{ textTransform: "none", flex: 1 ,background: value === 0 ? 'rgba(71, 45, 219, 0.50)' : "",color: 'white',fontSize:'15px'}} // Makes buttons equal width
        >
          Banners
        </Button>
        <Button
          variant={value === 1 ? "contained" : ""}
          onClick={(e) => handleChange(e, 1)}
          sx={{ textTransform: "none", flex: 1 ,background: value === 1 ? 'rgba(71, 45, 219, 0.50)' : "",color: 'white',fontSize:'15px'}} // Makes buttons equal width
        >
          Logos
        </Button>
      </div>

      {/* Panels */}
      <CustomTabPanel value={value} index={0}>
        <BannerPanel />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <LogosPanel />
      </CustomTabPanel>
    </Box>
  );
};

export default BannersLogosTabs;
